import {
    GroupId,
    UserProfileId
} from '@containers/activity-router/data-grid-container/student-list/student-list.types';
import { ActivityTypePaths } from '@containers/navigation/navigation-types';
import {
    TransformedAssignModalActivitiesStudensDataProps
} from '@services/gateways/classroom-report-gateway/classroom-report-gateway.types';

/**
 * CONSTANTS
 */
export type CourseType = Record<ActivityTypePaths, number>;

export const courseType: CourseType = {
    [ActivityTypePaths.activities]: 0,
    [ActivityTypePaths.skillQuests]: 1,
    [ActivityTypePaths.challenges]: 2,
    [ActivityTypePaths.assessments]: 3,
    [ActivityTypePaths.newCourses]: 4,
    [ActivityTypePaths.revise5]: 5,
    [ActivityTypePaths.tests]: 6
};

/**
 * FUNCTION PARAMS
 */
export type CombinedAssignGatewayClassroomsParams = {
    classroomId: number
    groupId: number
};

export type CombinedAssignGatewayRestrictedParams =
    CombinedAssignGatewayClassroomsParams & { isRestricted: boolean };

/**
 * SERVICE VALUES
 */
export type CombinedAssignCourseList = {
    id: string
    name: string
    yearGroups: Array<number>
};

export type CombinedAssignYearGroup = {
    yearGroup: number
    shortName: string
    longName: string
};

export type CombinedAssignGroup = {
    id: number
    name: string
    isDefault: boolean
    isRestrictedToAssignmentsOnly: boolean
    classicCourseId: number
    conquestCourseId: string
    upfCourseId: number
    studentsCount: number
};

export type CombinedAssignClassroom = {
    id: number
    name: string
    yearGroup: number
    groups: Array<CombinedAssignGroup>
    studentsCount: number
};

export type CombinedAssignSelectedGroupStudent = {
    userProfileId: UserProfileId
    encryptedUserProfileId: string
    firstName: string
    lastName: string
    groupId: GroupId
};

export type SelectedGroupStudents = Array<CombinedAssignSelectedGroupStudent>;

export enum ActivitiesComplexity {
    easier = 0,
    core = 1,
    all = 2
}

export enum ChallengesComplexity {
    ALL = 1,
    DOK2,
    DOK3,
    DOK4
}

export type CombinedAssignActivities = {
    id: string
    name: string
    activityType: number
    complexity: ActivitiesComplexity | ChallengesComplexity
    description: string | null
    extraProperties: any
};

type CombinedAssignTopic = {
    id: string
    name: string
    code: string | null
    description: string | null
    topics: Array<string> | null
    activities: Array<CombinedAssignActivities>
};

export type CombinedAssignTopics = {
    id: string
    name: string
    code: string | null
    description: string | null
    topics: Array<CombinedAssignTopic> | null
    activities: Array<CombinedAssignActivities> | null
};

export type CombinedAssignSelectedCourse = {
    id: string
    name: string
    locale?: string
    topics: Array<CombinedAssignTopics>
};

export type CombinedAssignInitData = {
    preferredCourseCountyCode: string
    classrooms: Array<CombinedAssignClassroom>
    selectedClassroomId: number
    selectedGroupId: number
    selectedGroupStudents: SelectedGroupStudents
    selectedCourseType: number
    selectedCourse: CombinedAssignSelectedCourse
};

export type CombinedAssignRequestStudentAssignment = {
    userProfileIds: Array<UserProfileId>
    activityType: number
    activityId: string
};

export type CombinedAssignRequest = {
    courseType: number
    productId: number
    classroomId: number
    courseId: string
    courseVersion?: number
    startDate: string | null
    dueDate: string | null
    studentAssignments: Array<CombinedAssignRequestStudentAssignment>
};

export type CombinedModifyUnassignRequestStudentAssignment = {
    userProfileId: UserProfileId
    assignmentIds: Array<number>
};

type CombinedAssignAssignmentReturnDefaultParams = {
    userProfileId: number
    incompleteAssignmentsCount: number
    studentUnavailableOverdueAssignmentsCount: number
    totalAssignmentsCount: number
    nextAssignmentDueDate: string | null
    completedAssignmentsCount: number
    averageAccuracy: number | null
};

type CombinedAssignAssignmentDefaultParams = {
    currentAssignmentId: number | null
    currentAssignmentStartDate: string | null
    currentAssignmentDueDate: string | null
    currentAssignmentUnavailableForStudent: boolean
    resultAssignmentType: number | null
    resultAccuracy: number | null
    resultCompletedDate: string | null
    attemptsCount: number
};

type CombinedAssignTopicSummaryDefaultParams = {
    courseTopicId: string
    assignedActivitiesCount: number
    averageAccuracy: number | null
};

/**
 * NEW COURSES PARAMS
 */
export type NewCoursesAssignment = CombinedAssignAssignmentDefaultParams & {
    questionSetId: string
	resultStudentSelfFeedbackIndicator: SelfReflections | null
};

type NewCoursesTopicSummary = CombinedAssignTopicSummaryDefaultParams & {
    courseTopicSummaries: NewCoursesTopicSummary[] | null
};

export type NewCoursesAssignmentReturn = CombinedAssignAssignmentReturnDefaultParams & {
    assignments: NewCoursesAssignment[]
    courseTopicSummaries: NewCoursesTopicSummary[]
};

export enum NewCoursesAssignmentResultAssignmentType {
    teacher,
    voluntary
}

export type NewCoursesAttempt = {
    questionSetId: string
    attempts: Array<{
        attempt: number
        completedDate: string
        accuracy: number
        studentSelfFeedbackIndicator: SelfReflections | null
        assignmentType: NewCoursesAssignmentResultAssignmentType
    }>
};

export type TransformedNewCoursesAssignments = Record<string, NewCoursesAssignmentReturn>;

/**
 * CHALLENGES PARAMS
 */
export type ChallengesAssignment = CombinedAssignAssignmentDefaultParams & {
    activityId: string
    inProgressAttemptTeacherStatus: CombinedAssignInProgressAttemptTeacherStatus
    inProgressAttemptWidgetInteractionStatus: CombinedAssignInProgressAttemptWidgetInteractionStatus
};

export type ChallengesAssignmentReturn = CombinedAssignAssignmentReturnDefaultParams & {
    assignments: ChallengesAssignment[]
    topicSummaries: ChallengesTopicSummary[]
};

type ChallengesTopicSummary = CombinedAssignTopicSummaryDefaultParams & {
    topicSummaries: ChallengesTopicSummary[]
};

export type TransformedChallengesAssignments = Record<string, ChallengesAssignmentReturn>;

/**
 * TRANSFORM LAYER
 */
export type TransformCombinedAssignGroup = CombinedAssignGroup & {
    disabled: boolean
};

export type TransformCombinedAssignClassroom = Omit<CombinedAssignClassroom, 'groups'> & {
    displayName: string
    disabled: boolean
    groups: Array<CombinedAssignGroup & TransformCombinedAssignGroup>
};

export type TransformCombinedAssignInitData = Omit<CombinedAssignInitData, 'classrooms' & 'selectedCourse'> & {
    classrooms: Array<TransformCombinedAssignClassroom>
    selectedCourse: TransformCourse
};

export type TransformCombinedAssignTopic = CombinedAssignTopic & { isExpanded: boolean };

export type TransformCombinedAssignTopics = Omit<CombinedAssignTopics, 'topics'> & {
    topics: Array<TransformCombinedAssignTopic> | null
};

export type TransformCourseTopics = TransformCombinedAssignTopics & {
    isExpanded: boolean
    filteredIndex?: number
};

export type TransformCourse = Omit<CombinedAssignSelectedCourse, 'topics'> & {
    topics: Array<TransformCourseTopics>
};

export enum SelfReflections {
	IM_SO_LOST,
	NOT_GREAT,
	IM_STARTING_TO_GET_IT,
	IM_GOOD,
	I_COULD_TEACH_IT
}

export type TransformedAssignModalNewCoursesStudentsDataProps =
    Pick<TransformedAssignModalActivitiesStudensDataProps, 'selectedGroupStudents'> & {
    transformedStudentAssignments: TransformedNewCoursesAssignments
    strands: TransformCourseTopics[]
};

export type TransformedAssignModalChallengesStudentsDataProps =
    Pick<TransformedAssignModalActivitiesStudensDataProps, 'selectedGroupStudents'> & {
    transformedStudentAssignments: TransformedChallengesAssignments
    strands: TransformCourseTopics[]
};

export enum CombinedAssignInProgressAttemptTeacherStatus {
    NONE,
    QUESTION_ASKED,
    TEACHER_REPLIED
}

export enum CombinedAssignInProgressAttemptWidgetInteractionStatus {
    NONE,
    SUBMITTED,
    REVIEWD
}
