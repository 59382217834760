import {
    CheckboxProps,
    FlexProps, InputProps
} from '@chakra-ui/react';

export enum GridCheckboxesVariants {
    studentCheckbox = 'studentCheckbox',
    topicCheckbox = 'topicCheckbox',
    activityCheckbox = 'activityCheckbox',
    assignedCheckbox = 'assignedCheckbox',
    dokCheckbox = 'dokCheckbox'
}

export type GridCheckboxProps = {
    checkboxProps: CheckboxProps
    styleProps?: FlexProps
    inputStyleProps?: InputProps
    showTooltipOnDisabled?: boolean
    tooltipLabel?: string
};
