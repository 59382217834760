import {
    defaultLevelFilterValue
} from '../../../components/challenges-course-filters/components/level-filter/level-filter-constants';
import { CourseData } from '@containers/activity-router/data-grid-container/courses/types/courses.types';
import { CheckboxStates } from '@containers/activity-router/data-grid-container/grid/grid.types';
import {
    DokFilterCourse
} from '@containers/activity-router/routes/learn/challenges/components/challenges-course-filters/components/dok-filter/dok-filter.types';
import { transformCourseData } from '@services/gateways/combined-assign-gateway/transform/courses/courses-transform';

type GetChallengesDokFilteredCourseProps = {
    course: CourseData
    dokCourseItems: DokFilterCourse
};

type GetChallengesDokFilteredCourseReturn = {
    updatedCourse: CourseData
    updatedFilterLevel: number | null
};

type GetFilteredLevelDataParams = {
    course: CourseData
};

type GetFilteredLevelDataReturn = {
    level: number | null
    count: number
};

type GetLevelDataCountParams = {
    course: CourseData
    level: number | null
};

export const getChallengesDokFilteredCourse = ({
    course,
    dokCourseItems
}: GetChallengesDokFilteredCourseProps): GetChallengesDokFilteredCourseReturn => {
    if (!course) return {
        updatedCourse: course,
        updatedFilterLevel: defaultLevelFilterValue
    };

    const updatedCourse = {
        ...course,
        topics: course.topics.map((strand) => {
            const dokStrandItem = dokCourseItems.find((dokStrand) => dokStrand.id === strand.id);
            const updatedTopics = strand.topics?.map((topic) => {
                const dokTopicItem = dokStrandItem?.topics.find((dokTopic) => dokTopic.id === topic.id);
                const updatedActivities = topic.activities.filter((activity) => {
                    const dokActivityItem = dokTopicItem?.activities.find((dokActivity) => dokActivity.id === activity.id);

                    return dokActivityItem?.checked !== CheckboxStates.unchecked;
                });

                return {
                    ...topic,
                    activities: updatedActivities
                };
            }).filter((topic) => topic !== null && topic.activities.length > 0) || [];

            return {
                ...strand,
                topics: updatedTopics
            };
        }).filter((strand) => strand !== null && strand.topics.length > 0) || []
    };
    const getFilteredLevelData = ({ course }: GetFilteredLevelDataParams): GetFilteredLevelDataReturn => {
        let level: number | null = null;
        let count = 0;

        if (course) {
            for (const strand of course.topics) {
                for (const topic of strand?.topics || []) {
                    for (const activity of topic.activities) {
                        const { yearGroup } = activity.extraProperties;

                        if (level === null) {
                            level = yearGroup;
                            count++;
                        } else if (level === yearGroup) {
                            count++;
                        } else {
                            return {
                                level: null,
                                count: 0
                            };
                        }
                    }
                }
            }
        }

        return {
            level,
            count
        };
    };
    const getLevelDataCount = ({ course, level }: GetLevelDataCountParams) => {
        if (!course || level === null) {
            return 0;
        }

        let total = 0;

        for (const strand of course.topics) {
            for (const topic of strand?.topics || []) {
                for (const activity of topic.activities) {
                    const { yearGroup } = activity.extraProperties;

                    if (yearGroup === level) {
                        total++;
                    }
                }
            }
        }

        return total;
    };
    const updatedFilterLevel = () => {
        const {
            level,
            count
        } = getFilteredLevelData({
            course: updatedCourse
        });
        const totalCount = getLevelDataCount({
            course,
            level
        });

        return count > 0 && count === totalCount ? level : null;
    };

    return {
        updatedCourse: transformCourseData(updatedCourse),
        updatedFilterLevel: updatedFilterLevel()
    };
};
