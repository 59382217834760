import {
    IconProps,
    TextProps
} from '@chakra-ui/react';
import React from 'react';

export enum DropdownVariant {
    halfStroke = 'dropdownHalfStroke',
    fullStroke = 'dropdownFullStroke'
}

export type RightIconProps = {
    iconProps?: IconProps
    textProps?: TextProps
};

export type DropdownValue = string | number;

export type DropdownDisplay = string | null;

export type DropdownOption = {
    value: DropdownValue
    label: string
    disabled?: boolean
    rightIcon?: ({
        iconProps,
        textProps
    }: RightIconProps) => React.ReactNode
};

export type DropdownProps = {
    options: Array<DropdownOption>
    value: DropdownValue
    onOptionClick: (value: DropdownValue) => void
    label: string
    variant?: DropdownVariant
    labelConstant?: DropdownDisplay
    styleProps?: React.CSSProperties
    isDisabled?: boolean
    showSingleItem?: boolean
	isLazy?: boolean
};
