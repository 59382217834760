const portalSidebarWidth = 70;

export const tabletLandscape = 1280;

export const tabletPortrait = 1024;

export const breakpoints = {
    tablet: `${tabletLandscape - portalSidebarWidth}px`,
    portrait: `${tabletPortrait - portalSidebarWidth}px`
};
